import Card from "../../data";
import chartImage from "../../assets/chart_blue.png";
import teamLogoPlaceholder from "../../assets/mavericks_logo.png";
import html from 'bundle-text:./inspect.html';
import css from 'bundle-text:./inspect.css';

const template = document.createElement("template");
template.innerHTML = `
<style>
${css}
</style>
${html}
`

class InspectScreen extends HTMLElement {
	static get observedAttributes() {
		return ["data-card-info"];
	}

	info: Card | null = null;

	inspectOuter: HTMLDivElement | null = null;
	cardPropertiesContainer: HTMLDivElement | null = null;
	cardNameDiv: HTMLDivElement | null = null;
	cardValueDiv: HTMLDivElement | null = null;
	playerPropertiesContainer: HTMLDivElement | null = null;
	playerNameDiv: HTMLDivElement | null = null;
	playerInfoDiv: HTMLDivElement | null = null;
	playerTeamNameDiv: HTMLDivElement | null = null;
	teamLogoDiv: HTMLDivElement | null = null;
	chartDiv: HTMLDivElement | null = null;


	constructor() {
		super();
		this.attachShadow({ mode: "open" });

		if (!this.shadowRoot)
			throw new Error("Shadow root not found");

		this.shadowRoot.appendChild(template.content.cloneNode(true));

		this.inspectOuter = this.shadowRoot.querySelector(".inspect-outer") as HTMLDivElement;
		this.cardPropertiesContainer = this.shadowRoot.querySelector(".card-properties-container") as HTMLDivElement;
		this.playerPropertiesContainer = this.shadowRoot.querySelector(".player-properties-container") as HTMLDivElement;
		this.cardNameDiv = this.shadowRoot.querySelector(".card-title") as HTMLDivElement;
		this.cardValueDiv = this.shadowRoot.querySelector(".card-value") as HTMLDivElement;
		this.playerNameDiv = this.shadowRoot.querySelector(".player-title") as HTMLDivElement;
		this.playerInfoDiv = this.shadowRoot.querySelector(".player-info") as HTMLDivElement;
		this.teamLogoDiv = this.shadowRoot.querySelector(".player-team-logo") as HTMLDivElement;
		this.chartDiv = this.shadowRoot.querySelector(".card-chart") as HTMLDivElement;

		if (this.inspectOuter) {
			this.inspectOuter.addEventListener("click", () => {
				this.remove();
			});
		}
		document.addEventListener("keydown", (e) => {
			if (e.key === "Escape") {
				this.remove();
			}
		});
		console.log("InspectScreen constructed");
	}
	connectedCallback() {
		if (!this.shadowRoot)
			throw new Error("Shadow root not found");

		//get attributes
		const _cardInfo = this.getAttribute("data-card-info");
		if (_cardInfo) {
			this.info = JSON.parse(_cardInfo);

			const middleDiv = this.shadowRoot.querySelector(".inspect-middle") as HTMLDivElement;

			const card = document.createElement("tilt-card");

			console.log(this.info);
			card.setAttribute("data-cardurl", this.info!.imageUrl);
			card.setAttribute("data-cutouturl", this.info!.cutoutUrl);
			card.setAttribute("data-slaburl", this.info!.slabUrl);
			card.setAttribute("data-raw", JSON.stringify(this.info));
			card.setAttribute('data-maxheight', "550");

			const cardName = this.info.info.title;
			const cardValue = this.info.info.value;
			const playerName = this.info.player.name;
			const playerTeamName = this.info.player.team;
			const playerInfo = this.info.player.info;

			if (this.cardNameDiv && cardName) {
				this.cardNameDiv.innerText = cardName;
			}
			if (this.cardValueDiv && cardValue) {
				this.cardValueDiv.innerText = cardValue;
			}
			if (this.playerNameDiv && playerName) {
				this.playerNameDiv.innerText = playerName;
			}
			if (this.playerTeamNameDiv && playerTeamName) {
				this.playerTeamNameDiv.innerText = playerTeamName;
			}
			if (this.playerInfoDiv && playerInfo) {
				this.playerInfoDiv.innerText = playerInfo;
			}

			if (this.cardPropertiesContainer)
				this.cardPropertiesContainer.innerHTML = "";
			if (this.playerPropertiesContainer)
				this.playerPropertiesContainer.innerHTML = "";

			const MAX_PROPERTIES = 9;
			let traitCount = 0;
			for (const trait of this.info!.info.cardTraits) {
				if (traitCount >= MAX_PROPERTIES)
					break;
				const propertyDiv = document.createElement("div");
				propertyDiv.classList.add("card-property");
				const title = document.createElement("div");
				title.classList.add("card-property-title");
				title.innerText = trait.title;
				const value = document.createElement("div");
				value.classList.add("card-property-value");
				value.innerText = trait.value;
				propertyDiv.appendChild(title);
				propertyDiv.appendChild(value);
				if (this.cardPropertiesContainer) {
					this.cardPropertiesContainer.appendChild(propertyDiv);
					traitCount++;
				}

			}
			let playerTraitCount = 0;
			for (const trait of this.info!.player.stats) {
				if (playerTraitCount >= MAX_PROPERTIES)
					break;
				const propertyDiv = document.createElement("div");
				propertyDiv.classList.add("card-property");
				const title = document.createElement("div");
				title.classList.add("card-property-title");
				title.innerText = trait.title;
				const value = document.createElement("div");
				value.classList.add("card-property-value");
				value.innerText = trait.value;
				propertyDiv.appendChild(title);
				propertyDiv.appendChild(value);
				if (this.playerPropertiesContainer) {
					this.playerPropertiesContainer.appendChild(propertyDiv);
					playerTraitCount++;
				}
			}

			this.teamLogoDiv!.style.backgroundImage = `url(${this.info!.player.teamLogoUrl})`;
			this.chartDiv!.style.backgroundImage = `url(${this.info!.info.chartImageUrl})`;

			middleDiv.appendChild(card);
		}
	}
	render() {

	}
}

window.customElements.define("inspect-screen", InspectScreen);